import { ref, computed } from "vue";

import * as Api from "@/api";
import { useStore, useGlobalStore } from "@/use/useStore";
import { usePermissions } from "@/use/usePermissions";
import { useUsers } from "@/use/useUsers";

export function useTgValidation() {
  const store = useStore();
  const globalStore = useGlobalStore();

  const { getUsers } = useUsers();
  const { definePermission } = usePermissions();
  const score = ref<Maybe<User>>();

  async function scoreListByTg(tgData: TgData) {
    const { data } = await Api.tgScoreList(tgData);
    score.value = data;
    return score.value;
  }

  async function loginTgData(tgData: TgData, score_id: number) {
    const { data: user } = await Api.tgValidation(tgData, score_id);

    const [schoolResponse, permissionsResponse] = await Promise.all([
      Api.fetchSchool(user.id),
      Api.fetchPermissions(user.id),
    ]);

    const school = schoolResponse.data;
    const permissions = permissionsResponse.data;

    await getUsers();

    const userWithSchool = { ...user, school };
    store.setCurrentUser(userWithSchool);
    store.setAuthUser(userWithSchool);

    definePermission(permissions);

    globalStore.value.user = userWithSchool;
  }

  return {
    score,
    loginTgData,
    scoreListByTg,
  };
}
